* {
  margin:0;
  padding:0;
  font-family: 'Quicksand', sans-serif;
}

html {
  --main-black: #121212;
  --main-red: #c7493a;
  --main-green: #689775;
  --main-blue: #266CC7;
  --title-text-color: rgba(255, 255, 255, 0.7);
  background-color: var(--main-black);
}

#landing-section {
  height: 790px;
  background-color: var(--main-black);
  display: flex;
  justify-content: center;
  align-items: center;
}

#landing-wrapper {
  width: 50%;
  height: 70%;
  display: flex;
  justify-content: center;
  position: relative;
}

#title-wrapper {
  width: 300px;
  height: 150px;
}

#title-main-text {
  font-size: 120px;
  text-align: center;
  text-shadow: 5px 0 10px rgba(0,0,0, 0.2);
  font-family: 'Quicksand', sans-serif;
  font-weight: 400;
  color: var(--main-red)
}

#file-submit-wrapper {
  position: absolute;
  bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 500px;
  height: 200px;
  box-shadow: 0 0 10px rgba(0,0,0, 0.4);
  background-color: rgba(0,0,0, 0.05);
  transition: box-shadow 0.3s;
}

#file-submit-wrapper:hover {
  box-shadow: 0 0 10px rgba(0,0,0, 0.6);
}

#file-section {
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#file-section > input {
  display: none;
}

#file-section > label {
  display: inline-block;
  width: 180px;
  height: 30px;
  border-radius: 5px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--main-black);
  background-color: rgba(199, 73, 58, 0.8);
  transition: background-color .2s;
  cursor: pointer;
}

#file-section > label:hover {
  background-color: rgba(199, 73, 58, 1);
}

#selectedFile {
  color: var(--title-text-color);
  margin-top: 6px;
}

#submit-section {
  margin-top: 17px;
}

#submit-section > button {
  width: 160px;
  height: 35px;
  border: none;
  border-radius: 5px;
  background-color: rgba(104, 151, 117, 0.8);
  transition: background-color .2s;
  color: var(--main-black);
  font-size: 15px;
  cursor: pointer;
}

#submit-section > button:hover {
  background-color: rgba(104, 151, 117, 1);
}