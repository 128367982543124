:root {
    --nav-opacity: 0;
    --data-position: -210px;
}

nav {
    background-color: rgba(18, 18, 18, var(--nav-opacity));
    width: 100%;
    height: 70px;
    position: fixed;
}

.hidden-nav {
    opacity: var(--nav-opacity);
    transition: opacity .3s ease-in;
}

#nav-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

#nav-title-wrapper {
    width: 80px;
    height: 40px;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
}

#nav-title-wrapper > p {
    font-size: 32px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    color: var(--main-red)
}

#links-container {
    position: absolute;
    right: 25px;
}